// import {greeting} from './greeting'
import '.././styles/main.scss'
import Vue from 'vue/dist/vue.min'
import Vuelidate from 'vuelidate'
import images from '../images/*.png'
import smoothscroll from 'smoothscroll-polyfill'
Vue.use(Vuelidate)
import {
    required,
    requiredIf,
    sameAs,
    email,
    between
} from 'vuelidate/lib/validators'
import axios from 'axios'

//safari polyfill
smoothscroll.polyfill()

Vue.filter('toCurrency', function (value) {
    // return value
    return parseFloat(value).toFixed(2)
    // return value.toFixed(2)
})

//config
const testMode = 0
// const stripePK = 'pk_test_51HeUdxAxFTDpEjHKfiijxWoRwJZCTMedLUc8fsJUyiEcZAw0fcVchHsXbR3L2uHzevsnyPdGasOlfgiGoyRenFpF00ZDgHe6z0'
const stripePK = 'pk_live_51HeUdxAxFTDpEjHK4CZ0Stnv15Wm3hAMsGmDYt6yZZKeWiv4iTevrgBwRW4NhsWiGIHx5pA9RUkpsNVMX4Hucx9c0084STmHkq'
var enginePath = '/engine'
var nativeTotal = 2500

var enableInputs = null
var disableInputs = null

var scrollTop = function () {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}

var errorHandler = function (error, timer) {

    app.errorMessage = error
    setTimeout(() => {
        app.errorMessage = ''
    }, timer)

    setTimeout(() => {
        document.getElementById("userErrors").scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }, 500)

    enableInputs()
}

var app = new Vue({
    el: '#giftCards',
    data: {
        fullName: '',
        email: '',
        emailConfirm: '',
        giftCard: true,
        tempHide: false,
        nameRec: '',
        emailRec: '',
        emailRecConfirm: '',
        message: '',
        finalCost: 25,
        template1: images.card_01,
        template2: images.card_02,
        template3: images.card_03,
        template: images.card_03,
        stepTwo: false,
        messageLoader: '',
        successScreen: false,
        errorMessage: '',
        submitStatus: null,
        payLock: false,
        hash: '',
        key: '',
        pdfLink: '',
        pdfLinkText: 'Download as PDF'
    },
    validations: {
        finalCost: {
            required,
            between: between(20, 250)
        },
        fullName: {
            required
        },
        email: {
            required,
            email
        },
        emailConfirm: {
            sameAsEmail: sameAs('email')
        },
        nameRec: {
            required: requiredIf(function (form) {
                return form.giftCard
            }),
        },
        emailRec: {
            required: requiredIf(function (form) {
                return form.giftCard
            }),
            email
        },
        emailRecConfirm: {
            required: requiredIf(function (form) {
                return form.giftCard
            }),
            sameAsRecEmail: sameAs('emailRec')
        },
    },
    computed: {
        charactersLeft() {
            var count = this.message.length
            return count
        }
    },
    methods: {
        submit() {
            this.$v.$touch()
            // console.log(this.$v.$invalid)
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
                console.log('ERROR')
                this.stepTwo = false
            } else {
                // do your submit logic here
                this.submitStatus = 'PENDING'
                setTimeout(() => {
                    this.submitStatus = 'OK'
                }, 500)

                var stripeNative = Stripe(stripePK)

                //native payments
                var paymentRequest = stripeNative.paymentRequest({
                    country: 'AU',
                    currency: 'aud',
                    total: {
                        label: 'Gift Card',
                        amount: nativeTotal,
                    },
                    // requestPayerName: true,
                    // requestPayerEmail: true,
                    // requestPayerPhone: true,
                })

                var elements = stripeNative.elements()
                var prButton = elements.create('paymentRequestButton', {
                    paymentRequest: paymentRequest,
                    style: {
                      paymentRequestButton: {
                        type: 'buy',
                        // One of 'default', 'book', 'buy', or 'donate'
                        // Defaults to 'default'
                  
                        theme: 'dark',
                        // One of 'dark', 'light', or 'light-outline'
                        // Defaults to 'dark'
                  
                        height: '48px'
                        // Defaults to '40px'. The width is always '100%'.
                      },
                    }
                })

                // Check the availability of the Payment Request API first.
                paymentRequest.canMakePayment().then(function (result) {
                    if (result) {
                        prButton.mount('#payment-request-button')
                    } else {
                        document.getElementById('payment-request-button').style.display = 'none'
                        document.getElementById('payment-request-button-info').style.display = 'none'
                    }
                })

                paymentRequest.on('token', function (result) {

                    var additionalData = {
                        token: result.token.id,
                        fullName: app.fullName,
                        email: app.email,
                        emailConfirm: app.emailConfirm,
                        giftCard: app.giftCard,
                        nameRec: app.nameRec,
                        emailRec: app.emailRec,
                        emailRecConfirm: app.emailRecConfirm,
                        message: app.message,
                        finalCost: app.finalCost,
                        template: app.template,
                        paymentIntentId: null
                    }

                    axios({
                            method: 'post',
                            url: enginePath + '/charge-token.php',
                            data: additionalData
                            // crossDomain: true
                        })
                        .then(function (response) {

                            if (response.data.success) {
                                //close native screen
                                result.complete('success')

                                scrollTop()

                                app.stepTwo = false
                                app.successScreen = true
                                app.hash = response.data.hash
                                app.key = response.data.key
                                console.log(response.data.hash);
                                console.log(response.data.key);
                                app.pdfLink = enginePath + '/pdf.php?hash=' + app.hash + '&key=' + app.key

                            } else {

                                //close native screen
                                result.complete('fail')
                                errorHandler(response.data.error, 16000);

                            }

                            enableInputs()

                        })
                        .catch(function () {

                            //close native screen
                            result.complete('fail')
                            errorHandler("Someting went wrong. Please try again.", 8000);

                        })

                })

                paymentRequest.on('cancel', function () {
                    console.log('payment cancelled')

                    app.errorMessage = 'Your payment has been cancelled. Please try again.'
                    setTimeout(() => {
                        app.errorMessage = ''
                    }, 8000)
                })

                scrollTop()

                setTimeout(() => {
                    this.stepTwo = true
                }, 400)

                setTimeout(() => {
                    this.tempHide = true
                }, 1000)
            }
        },
        flipper(cost) {
            this.finalCost = cost
            nativeTotal = cost * 100
        },
        templateSwitch(template) {
            this.template = template
        },
        initialState() {
            this.stepTwo = false
            this.tempHide = false
        },
        resetStates() {

            if (testMode) {

                this.stepTwo = false
                this.tempHide = false
                this.successScreen = false
                this.hash = ''
                this.key = ''

            } else {

                // this.fullName = ''
                // this.email = ''
                // this.emailConfirm = ''
                this.giftCard = true
                this.tempHide = false
                this.nameRec = ''
                this.emailRec = ''
                this.emailRecConfirm = ''
                this.message = ''
                this.finalCost = 25
                this.template = images.card_03
                this.stepTwo = false
                this.messageLoader = ''
                this.successScreen = false
                this.errorMessage = ''
                this.submitStatus = null
                this.payLock = false
                this.hash = ''
                this.key = ''
                this.pdfLink = '',
                this.pdfLinkText = 'Download as PDF'

            }

        },
        downloadPDF() {

            this.pdfLinkText = 'Downloading now...'

        }
    },
    mounted: function () {

        'use strict'

        var stripe = Stripe(stripePK)

        function registerElements(elements, paymentForm) {
            var formClass = '.' + paymentForm
            var paymentFormInner = document.querySelector(formClass)
            var form = paymentFormInner.querySelector('form')

            enableInputs = function () {
                Array.prototype.forEach.call(
                    form.querySelectorAll(
                        "input[type='text'], input[type='email'], input[type='tel']"
                    ),
                    function (input) {
                        input.removeAttribute('disabled')
                    }
                )

                app.payLock = false
                app.messageLoader = ''
                document.getElementById("payNow").disabled = false
            }

            disableInputs = function () {
                Array.prototype.forEach.call(
                    form.querySelectorAll(
                        "input[type='text'], input[type='email'], input[type='tel']"
                    ),
                    function (input) {
                        input.setAttribute('disabled', 'true')
                    }
                )
            }

            function triggerBrowserValidation() {
                var submit = document.createElement('input')
                submit.type = 'submit'
                submit.style.display = 'none'
                form.appendChild(submit)
                submit.click()
                submit.remove()
            }

            form.addEventListener('submit', function (e) {
                app.messageLoader = '<h3>We are setting up your Gift Card.<br> This process will take 10-15 seconds.</h3> Please do not refresh this page'
                e.preventDefault()

                var plainInputsValid = true
                Array.prototype.forEach.call(form.querySelectorAll('input'), function (
                    input
                ) {
                    if (input.checkValidity && !input.checkValidity()) {
                        plainInputsValid = false
                        return
                    }
                })
                if (!plainInputsValid) {
                    triggerBrowserValidation()
                    return
                }
                disableInputs()

                // Gather additional customer data we may have collected in our form.
                var name = form.querySelector('#paymentForm-name')
                var additionalData = {
                    name: name ? name.value : undefined
                }

                if (!app.payLock) {
                    document.getElementById("payNow").disabled = true
                    stripe.createToken(elements[0], additionalData).then(function (result) {

                        if (result.token) {

                            var additionalData = {
                                token: result.token.id,
                                fullName: app.fullName,
                                email: app.email,
                                emailConfirm: app.emailConfirm,
                                giftCard: app.giftCard,
                                nameRec: app.nameRec,
                                emailRec: app.emailRec,
                                emailRecConfirm: app.emailRecConfirm,
                                message: app.message,
                                finalCost: app.finalCost,
                                template: app.template,
                                paymentIntentId: null
                            }

                            axios({
                                    method: 'post',
                                    url: enginePath + '/create-payment-intent.php',
                                    data: additionalData
                                })
                                .then(function (response) {
                                    // console.table(response.data)
                                    if (response.data.success) {

                                        if (!response.data.requires_action) {

                                            scrollTop()

                                            app.stepTwo = false
                                            app.successScreen = true
                                            app.hash = response.data.hash
                                            app.key = response.data.key
                                            console.log(response.data.hash);
                                            console.log(response.data.key);
                                            app.pdfLink = enginePath + '/pdf.php?hash=' + app.hash + '&key=' + app.key

                                            enableInputs()

                                        } else {

                                            app.messageLoader = '<h3>We are setting up your Gift Card.<br> This process will take 10-15 seconds.</h3> Please do not refresh this page'

                                            stripe.handleCardAction(
                                                response.data.payment_intent_client_secret
                                            ).then(function (result) {


                                                if (result.error) {

                                                    errorHandler(result.error.message, 8000);

                                                } else {

                                                    additionalData['paymentIntentId'] = result.paymentIntent.id

                                                    axios({
                                                            method: 'post',
                                                            url: enginePath + '/confirm-payment-intent.php',
                                                            data: additionalData
                                                            // crossDomain: true
                                                        })
                                                        .then(function (response) {

                                                            if (response.data.success) {

                                                                scrollTop()

                                                                app.stepTwo = false
                                                                app.successScreen = true
                                                                app.hash = response.data.hash
                                                                app.key = response.data.key
                                                                console.log(response.data.hash);
                                                                console.log(response.data.key);
                                                                app.pdfLink = enginePath + '/pdf.php?hash=' + app.hash + '&key=' + app.key

                                                            } else {

                                                                errorHandler(response.data.error, 8000);

                                                            }

                                                            enableInputs()

                                                        })
                                                        .catch(function (error) {

                                                            errorHandler("Someting went wrong. Please try again.", 8000);

                                                        })

                                                }

                                            })

                                        }

                                    } else {

                                        errorHandler(response.data.error, 8000);

                                    }


                                })
                                .catch(function (error) {

                                    errorHandler("Someting went wrong. Please try again.", 8000);

                                })

                        } else {

                            errorHandler(result.error.message, 8000);
                            enableInputs()

                        }

                    })
                }
                app.payLock = true

            })

        }

        // ---

        (function () {
            'use strict'

            var elements = stripe.elements({
                locale: 'auto'
            })

            // Floating labels
            var inputs = document.querySelectorAll('.paymentForm .input')

            Array.prototype.forEach.call(inputs, function (input) {

                input.addEventListener('focus', function () {
                    input.classList.add('focused')
                })

                input.addEventListener('blur', function () {
                    input.classList.remove('focused')
                })

                input.addEventListener('keyup', function () {
                    if (input.value.length === 0) {
                        input.classList.add('empty')
                    } else {
                        input.classList.remove('empty')
                    }
                })

            })

            var elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            }

            var elementStyles = {
                base: {
                    fontSize: '16px',
                    fontFamily: 'Arial, Helvetica, sans-serif',
                    // backgroundColor: '#fff'
                }
            }

            var cardNumber = elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses
            })
            cardNumber.mount('#paymentForm-card-number')

            var cardExpiry = elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses
            })
            cardExpiry.mount('#paymentForm-card-expiry')

            var cardCvc = elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses
            })
            cardCvc.mount('#paymentForm-card-cvc')

            registerElements([cardNumber, cardExpiry, cardCvc], 'paymentForm')
        })()

    }
})

//mock
if (testMode) {
    enginePath = 'http://localhost:8888/engine'
    app.fullName = 'Retail Zoo'
    app.email = 'asanga@gmail.com'
    app.emailConfirm = 'asanga@gmail.com'
    app.nameRec = 'Asanga Dev'
    app.emailRec = 'asanga.dev@gmail.com'
    app.emailRecConfirm = 'asanga.dev@gmail.com'
    app.message = 'Hi Jess, wishing you the most amazing birthday! Enjoy it at your favourite place, Betty’s Burgers!'
}